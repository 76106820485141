import { validateMobile } from "@/libs/validate";
import { shortcuts } from "@/libs/shortcuts";
export default {
  name: "search-table",
  data() {
    return {
      tableSize: "default",
      openSearch: true,
      // 显示搜索
      openTip: true,
      // 显示提示
      loading: true,
      // 表单加载状态
      selectList: [],
      // 多选数据
      searchForm: {
        // 搜索框对应data对象
        name: "",
        status: "",
        pageNumber: 1,
        // 当前页数
        pageSize: 10,
        // 页面大小
        sort: "createTime",
        // 默认排序字段
        order: "desc",
        // 默认排序方式
        startDate: "",
        // 起始时间
        endDate: "" // 终止时间
      },

      selectDate: null,
      // 选择日期绑定modal
      options: {
        shortcuts: shortcuts
      },
      modalType: 0,
      // 添加或编辑标识
      modalVisible: false,
      // 添加或编辑显示
      modalTitle: "",
      // 添加或编辑标题
      form: {
        // 添加或编辑表单对象初始化数据
        sex: 1,
        type: 0,
        name: "",
        mobile: "",
        email: ""
      },
      formValidate: {
        // 表单验证规则
        name: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        mobile: [{
          required: true,
          message: "请输入手机号",
          trigger: "blur"
        }, {
          validator: validateMobile,
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: "请输入邮箱地址"
        }, {
          type: "email",
          message: "邮箱格式不正确"
        }]
      },
      submitLoading: false,
      // 添加或编辑提交状态
      columns: [
      // 表头
      {
        type: "selection",
        width: 60,
        align: "center",
        fixed: "left"
      }, {
        type: "index",
        width: 60,
        align: "center",
        fixed: "left"
      }, {
        title: "名称",
        key: "name",
        minWidth: 150,
        sortable: true,
        fixed: "left"
      }, {
        title: "手机",
        key: "mobile",
        width: 130,
        sortable: true
      }, {
        title: "邮箱",
        key: "email",
        width: 180,
        sortable: true
      }, {
        title: "性别",
        key: "sex",
        width: 100,
        align: "center",
        render: (h, params) => {
          let re = "";
          if (params.row.sex == 1) {
            re = "男";
          } else if (params.row.sex == 0) {
            re = "女";
          }
          return h("div", re);
        }
      }, {
        title: "类型",
        key: "type",
        width: 120,
        align: "center",
        render: (h, params) => {
          let re = "";
          if (params.row.type == 0) {
            re = "类型1";
          } else if (params.row.type == 1) {
            re = "类型2";
          }
          return h("div", re);
        }
      }, {
        title: "状态",
        key: "status",
        align: "center",
        width: 150,
        render: (h, params) => {
          let re = "";
          if (params.row.status == 0) {
            return h("div", [h("Badge", {
              props: {
                status: "success",
                text: "启用"
              }
            })]);
          } else if (params.row.status == -1) {
            return h("div", [h("Badge", {
              props: {
                status: "error",
                text: "禁用"
              }
            })]);
          }
        },
        filters: [{
          label: "启用",
          value: 0
        }, {
          label: "禁用",
          value: -1
        }],
        filterMultiple: false,
        filterMethod(value, row) {
          return row.status == value;
        }
      }, {
        title: "创建时间",
        key: "createTime",
        width: 170,
        sortable: true,
        sortType: "desc"
      }, {
        title: "操作",
        key: "action",
        width: 180,
        align: "center",
        fixed: "right",
        render: (h, params) => {
          let enableOrDisable = "";
          if (params.row.status == 0) {
            enableOrDisable = h("a", {
              on: {
                click: () => {
                  this.disable(params.row);
                }
              }
            }, "禁用");
          } else {
            enableOrDisable = h("a", {
              on: {
                click: () => {
                  this.enable(params.row);
                }
              }
            }, "启用");
          }
          return h("div", [h("a", {
            on: {
              click: () => {
                this.edit(params.row);
              }
            }
          }, "编辑"), h("Divider", {
            props: {
              type: "vertical"
            }
          }), enableOrDisable, h("Divider", {
            props: {
              type: "vertical"
            }
          }), h("a", {
            on: {
              click: () => {
                this.remove(params.row);
              }
            }
          }, "删除")]);
        }
      }],
      data: [],
      // 表单数据
      total: 0 // 表单数据总数
    };
  },

  methods: {
    init() {
      this.getDataList();
    },
    changePage(v) {
      this.searchForm.pageNumber = v;
      this.getDataList();
      this.clearSelectAll();
    },
    changePageSize(v) {
      this.searchForm.pageSize = v;
      this.getDataList();
    },
    selectDateRange(v) {
      if (v) {
        this.searchForm.startDate = v[0];
        this.searchForm.endDate = v[1];
      }
    },
    changeTableSize(v) {
      this.tableSize = v;
    },
    exportData() {
      this.$refs.table.exportCsv({
        filename: "数据"
      });
    },
    getDataList() {
      this.loading = true;
      // 带多条件搜索参数获取表单数据 请自行修改接口
      // this.getRequest("请求路径", this.searchForm).then(res => {
      //   this.loading = false;
      //   if (res.success) {
      //     this.data = res.result.content;
      //     this.total = res.result.totalElements;
      //     if (this.data.length == 0 && this.searchForm.pageNumber > 1) {
      //       this.searchForm.pageNumber -= 1;
      //       this.getDataList();
      //     }
      //   }
      // });
      // 以下为模拟数据
      this.data = [{
        id: "1",
        name: "XBoot",
        mobile: "12345678901",
        email: "1012139570@qq.com",
        sex: 1,
        type: 0,
        status: 0,
        createTime: "2018-08-08 00:08:00"
      }, {
        id: "2",
        name: "Exrick",
        mobile: "12345678901",
        email: "1012139570@qq.com",
        sex: 0,
        type: 1,
        status: -1,
        createTime: "2018-08-08 00:08:00"
      }];
      this.total = this.data.length;
      this.loading = false;
    },
    handleSearch() {
      this.searchForm.pageNumber = 1;
      this.searchForm.pageSize = 10;
      this.getDataList();
    },
    handleReset() {
      this.$refs.searchForm.resetFields();
      this.searchForm.pageNumber = 1;
      this.searchForm.pageSize = 10;
      this.selectDate = null;
      this.searchForm.startDate = "";
      this.searchForm.endDate = "";
      // 重新加载数据
      this.getDataList();
    },
    changeSort(e) {
      this.searchForm.sort = e.key;
      this.searchForm.order = e.order;
      if (e.order == "normal") {
        this.searchForm.order = "";
      }
      this.getDataList();
    },
    handelSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          if (this.modalType == 0) {
            // 添加 避免编辑后传入id等数据 记得删除
            delete this.form.id;
            delete this.form.status;
            // this.postRequest("请求路径", this.form).then(res => {
            //   this.submitLoading = false;
            //   if (res.success) {
            //     this.$Message.success("操作成功");
            //     this.getDataList();
            //     this.modalVisible = false;
            //   }
            // });
            // 模拟成功
            this.submitLoading = false;
            this.$Message.success("操作成功");
            this.modalVisible = false;
          } else if (this.modalType == 1) {
            // 编辑
            // this.postRequest("请求路径", this.form).then(res => {
            //   this.submitLoading = false;
            //   if (res.success) {
            //     this.$Message.success("操作成功");
            //     this.getDataList();
            //     this.modalVisible = false;
            //   }
            // });
            // 模拟成功
            this.submitLoading = false;
            this.$Message.success("操作成功");
            this.modalVisible = false;
          }
        }
      });
    },
    add() {
      this.modalType = 0;
      this.modalTitle = "添加";
      this.$refs.form.resetFields();
      this.modalVisible = true;
    },
    edit(v) {
      this.modalType = 1;
      this.modalTitle = "编辑";
      this.$refs.form.resetFields();
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.form = data;
      this.modalVisible = true;
    },
    enable(v) {
      this.$Modal.confirm({
        title: "确认启用",
        // 记得确认修改此处
        content: "您确认要启用 " + v.name + " ?",
        loading: true,
        onOk: () => {
          // this.postRequest("请求路径，如/deleteByIds/" + v.id).then(res => {
          //   this.$Modal.remove();
          //   if (res.success) {
          //     this.$Message.success("操作成功");
          //     this.getDataList();
          //   }
          // });
          //模拟成功
          this.$Message.success("操作成功");
          this.$Modal.remove();
          this.getDataList();
        }
      });
    },
    disable(v) {
      this.$Modal.confirm({
        title: "确认禁用",
        // 记得确认修改此处
        content: "您确认要禁用 " + v.name + " ?",
        loading: true,
        onOk: () => {
          // this.postRequest("请求路径，如/deleteByIds/" + v.id).then(res => {
          //   this.$Modal.remove();
          //   if (res.success) {
          //     this.$Message.success("操作成功");
          //     this.getDataList();
          //   }
          // });
          //模拟成功
          this.$Message.success("操作成功");
          this.$Modal.remove();
          this.getDataList();
        }
      });
    },
    remove(v) {
      this.$Modal.confirm({
        title: "确认删除",
        // 记得确认修改此处
        content: "您确认要删除 " + v.name + " ?",
        loading: true,
        onOk: () => {
          // 删除
          // this.deleteRequest("请求地址，如/deleteByIds/" + v.id).then(res => {
          //   this.$Modal.remove();
          //   if (res.success) {
          //     this.$Message.success("操作成功");
          //     this.clearSelectAll();
          //     this.getDataList();
          //   }
          // });
          // 模拟请求成功
          this.$Message.success("操作成功");
          this.clearSelectAll();
          this.$Modal.remove();
          this.getDataList();
        }
      });
    },
    showSelect(e) {
      this.selectList = e;
    },
    clearSelectAll() {
      this.$refs.table.selectAll(false);
    },
    delAll() {
      if (this.selectList.length <= 0) {
        this.$Message.warning("您还未选择要删除的数据");
        return;
      }
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除所选的 " + this.selectList.length + " 条数据?",
        loading: true,
        onOk: () => {
          let ids = "";
          this.selectList.forEach(function (e) {
            ids += e.id + ",";
          });
          ids = ids.substring(0, ids.length - 1);
          // 批量删除
          // this.deleteRequest("请求地址，如/deleteByIds/" + ids).then(res => {
          //   this.$Modal.remove();
          //   if (res.success) {
          //     this.$Message.success("操作成功");
          //     this.clearSelectAll();
          //     this.getDataList();
          //   }
          // });
          // 模拟请求成功
          this.$Message.success("操作成功");
          this.$Modal.remove();
          this.clearSelectAll();
          this.getDataList();
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};